import React from 'react';
import Container from '@components/containers/Container';
import Heading, { HeadingProps } from '@components/views/Heading';
import { ILinkACF } from 'src/types/ILinkACF';

const classNames =
  'relative z-10 bg-neutral-1 inline-block py-24 pr-24 text-neutral-9 font-extralight text-4xl lg:text-5xl xl:text-6xxl';

const Line: React.FunctionComponent = () => (
  <div className="bg-neutral-9 h-1 xl:h-2 w-full md:absolute top-1/2 left-0" />
);

const Link: React.FunctionComponent<{
  link: ILinkACF;
  classes?: string;
}> = ({ link, classes }) => (
  <a
    href={link.url}
    target={link.target}
    className={`${classNames} ${classes} transition-colors hover:text-primary hover:underline`}
  >
    {link.title}
  </a>
);

interface ContactCtaSectionProps {
  heading: HeadingProps;
  texts: Array<{
    acf_fc_layout: string;
    link: ILinkACF;
    type: Array<{
      acf_fc_layout: string;
      title?: string;
      link?: ILinkACF;
    }>;
  }>;
}

const ContactCtaSection: React.FunctionComponent<ContactCtaSectionProps> = (
  props,
) => {
  const { heading, texts } = props;

  return (
    <section className="bg-neutral-1">
      <Container className="py-40 md:py-80">
        <div className="relative">
          <Line />
          <div className="relative z-10 bg-neutral-1 inline-block py-24 pr-24 md:pl-24 text-neutral-4 font-extralight text-4xl lg:text-5xl xl:text-6xxl transform md:translate-x-1/3">
            <Heading {...heading} />
          </div>
        </div>
        {texts.map((item, index) => {
          const { link, acf_fc_layout } = item;

          if (acf_fc_layout === 'single' && link) {
            return (
              <div className="relative" key={index}>
                <Line />
                <Link link={link} />
              </div>
            );
          }

          if (acf_fc_layout === 'row') {
            const { type } = item;

            return (
              <div
                key={index}
                className="relative flex-col md:flex-row flex justify-between"
              >
                <Line />
                {type.map((item, index) => {
                  const { acf_fc_layout } = item;

                  if (acf_fc_layout === 'text') {
                    const { title } = item;

                    return (
                      <div
                        key={index}
                        className={`${classNames} hidden md:block`}
                      >
                        {title}
                      </div>
                    );
                  }

                  if (acf_fc_layout === 'link' && item.link) {
                    const { link } = item;

                    return (
                      <Link
                        key={index}
                        link={link}
                        classes={index > 0 ? 'md:pl-24 md:mr-96' : ''}
                      />
                    );
                  }
                })}
              </div>
            );
          }
        })}
      </Container>
    </section>
  );
};
export default ContactCtaSection;
